<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <!-- 新規登録時：displayFlg==1 -->
      <NavBar v-if="displayFlg == '1'" tittle="label.lbl_locationMasterAdd" />
      <!-- 修正時：displayFlg == 2 -->
      <NavBar v-if="displayFlg == '2'" tittle="label.lbl_locationMasterDelete" />
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <span class="search-autocomplete first-search-item">
              <!--倉庫名/倉庫コード -->
              <div class="search-autocomplete first-search-item">
                <v-text-field
                  dense
                  readonly
                  v-model="warehouseCdName"
                  :items="warehouseList"
                  :label="$t('label.lbl_warehouseNameCd')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                >
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-text-field>
              </div>
            </span>
            <span class="item-spacer">&nbsp;&nbsp;</span>
            <!--状況 -->
            <span class="search-autocomplete first-search-item" v-if="displayFlg == '2'">
              <v-text-field
                dense
                readonly
                v-model="situation"
                :items="warehouseList"
                :label="$t('label.lbl_Situatuion')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-text-field>
            </span>
            <v-row cols="2" />
            <div class="btn-area">
              <!--行追加ボタン-->
              <span v-if="displayFlg == '1'">
                <v-btn color="primary" id="btn-search" class="other-btn" @click="lineAdd">{{
                  $t("btn.btn_rowsAdd")
                }}</v-btn>
              </span>
              <!--行追加ボタン-->
              <span v-if="displayFlg == '2'">
                <v-btn color="primary" id="btn-search" class="other-btn" @click="lineAdd">{{
                  $t("btn.btn_rowsAdd")
                }}</v-btn>
              </span>
              <!--登録ボタン-->
              <span v-if="displayFlg == '1'">
                <v-btn color="primary" id="btn-search" class="post-btn" @click="upadateAdd">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </span>
              <!--修正ボタン-->
              <span v-if="displayFlg == '2'">
                <v-btn color="primary" id="btn-search" class="post-btn" @click="upadateAdd">{{
                  $t("btn.btn_fix")
                }}</v-btn>
              </span>
            </div>
          </v-row>
          <v-row v-if="displayFlg == '2'" class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <div class="search-row-exeBtns">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" id="get-search" class="other-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <v-row cols="5" />
            </div>
          </v-row>
          <!-- 戻るボタンを押下時出現ダイアログ -->
          <v-dialog v-model="updateDialogMessage" :max-width="800">
            <v-card>
              <v-card-title class="blue-grey lighten-3" primary-title>
                {{ $t("btn.btn_ok") }}
              </v-card-title>
              <v-card-text class="pa-4">
                <p>{{ backMessage }}</p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="updateOks"> {{ $t("btn.btn_ok_en") }} </v-btn>
                <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- チェックボックスチェック後ダイアログ -->
          <v-dialog v-model="checkDialogMessage" :max-width="800">
            <v-card>
              <v-card-title class="blue-grey lighten-3" primary-title>
                {{ $t("btn.btn_ok") }}
              </v-card-title>
              <v-card-text class="pa-4">
                <p>{{ backMessage }}</p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="close"> {{ $t("btn.btn_close") }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
        <!-- データテーブル -->
        <v-form ref="editedList" lazy-validation>
          <v-container fluid>
            <v-data-table
              id="listData"
              fixed-header
              :headers="headerItems"
              :items="inputList"
              disable-filtering
              disable-sort
              :hide-default-footer="true"
              height="530px"
              :page.sync="page"
              :items-per-page="itemsPerPage"
            >
              <!-- チェックボックス -->
              <template v-slot:[`item.check`]="{ item }">
                <input type="checkbox" v-model="item.check" style="transform: scale(2)" />
              </template>
              <!-- ロケーションNo -->
              <template v-slot:[`item.locationNo`]="{ item }">
                <v-text-field
                  outlined
                  dense
                  :disabled="item.disableEdit"
                  v-model="item.locationNo"
                  clear-icon="mdi-close-circle input-number"
                  clearable
                  :rules="item.check ? [rules.inputRequired] : []"
                  class="locationAlign"
                ></v-text-field>
              </template>
              <!-- ロケグループ名 -->
              <template v-slot:[`item.locationGroupHeader`]="{ item, index }">
                <v-autocomplete
                  dense
                  v-model="item.locationGroupName"
                  :disabled="item.disableEdit"
                  @change="(event) => changeLocation(event, item, index)"
                  :hint="checkLocation(item, index)"
                  :items="locationsList"
                  class="text-box"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                  :rules="item.check ? [rules.inputRequired] : []"
                >
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </template>
              <!-- 担当者 -->
              <template v-slot:[`item.popDestinationNm`]="{ item }">
                <v-autocomplete
                  dense
                  v-model="item.userSid"
                  :disabled="item.disableEdit"
                  :items="managerList"
                  class="text-box"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                >
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </template>
              <!-- 有効開始日時 -->
              <template v-slot:[`item.validFrom`]="{ item, index }">
                <div class="calender date-style">
                  <v-text-field
                    outlined
                    dense
                    v-model="item.validFrom"
                    :disabled="item.disableEdit"
                    class="txt-single date-style input-number"
                    @change="changeDateFrom(item.validFrom, index)"
                    :rules="[rules.inputRequired, rules.yyyymmdd, inputValidDateCheck(item, index)]"
                    clear-icon="mdi-close-circle"
                    clearable
                    :ref="'validFrom' + index"
                  ></v-text-field>
                  <div class="calender-btn-area">
                    <v-menu
                      v-model="item.dateMenuFrom"
                      :disabled="item.disableEdit"
                      :close-on-content-click="false"
                      :nudge-right="-100"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      class="date-calendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small :disabled="item.disableEdit">
                          <v-icon>mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="item.validFromCal"
                        @input="inputCallenderFrom(item.validFromCal, index)"
                      />
                    </v-menu>
                  </div>
                </div>
              </template>
              <!-- 有効終了日時 -->
              <template v-slot:[`item.validTo`]="{ item, index }">
                <div class="calender date-style">
                  <v-text-field
                    outlined
                    dense
                    v-model="item.validTo"
                    class="input-carender input-number"
                    :disabled="item.disableEdit"
                    @change="changeDateTo(item.validTo, index)"
                    clear-icon="mdi-close-circle"
                    clearable
                    :rules="[
                      rules.inputRequired,
                      rules.yyyymmdd,
                      rules.inputDateCheck(item.validFrom, item.validTo),
                    ]"
                    :ref="'validTo' + index"
                  ></v-text-field>
                  <div class="calender-btn-area">
                    <v-menu
                      v-model="item.dateMenuTo"
                      :disabled="item.disableEdit"
                      :close-on-content-click="false"
                      :nudge-right="-100"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      class="date-calendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small :disabled="item.disableEdit">
                          <v-icon>mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="item.validToCal"
                        @input="inputCallenderTo(item.validToCal, index)"
                      />
                    </v-menu>
                  </div>
                </div>
              </template>
              <!-- ロケ優先度 -->
              <template v-slot:[`item.locationPriorityHeader`]="{ item }">
                <v-autocomplete
                  dense
                  :items="locationGroupPriorityList"
                  v-model="item.locationPriority"
                  class="text-box"
                  :disabled="item.disableEdit"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                  :rules="item.check ? [rules.inputRequired] : []"
                >
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                </v-autocomplete>
              </template>
              <!-- ロケ詳細 -->
              <template v-slot:[`item.locationBatchIn`]="{ item, index }">
                <v-btn small text @click="openDetailsAdd(index)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <!-- ページネーション -->
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="Math.ceil(inputList.length / itemsPerPage)"
                :total-visible="7"
              ></v-pagination>
            </div>
          </v-container>
        </v-form>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- ロケーション登録ポップアップ明細-->
      <v-dialog v-model="dialogNewLocationGr" :max-width="400" persistent>
        <v-form ref="locationadd">
          <v-card>
            <v-card-title id="sub-bar">
              <span id="lbl-screen-name">{{ $t("label.lbl_locationGroupIn") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <div class="add-textbox">
                    <v-text-field
                      outlined
                      dense
                      v-model="popNewLocation"
                      maxlength="5"
                      @change="change5"
                      :rules="[rules.isNumbers]"
                      :label="$t('label.lbl_locationGroup')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!-- <span class="require" style="padding-top: 20px">*</span> -->
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="api-btn" @click="popNewLoationClose">
                {{ $t("btn.btn_close") }}
              </v-btn>
              <v-btn color="primary" class="api-btn" @click="popNewLoationSave">
                {{ $t("btn.btn_insert") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <!-- ロケ詳細入力ポップアップ -->
      <v-dialog v-model="openDatailsAdd" :max-width="800">
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_locationDetailIn") }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="detailForm" lazy-validation>
              <v-container fluid>
                <!-- １行目 -->
                <v-row class="search-row">
                  <!-- ロケ管理区分 -->
                  <div class="search-autocomplete first-search-item">
                    <v-autocomplete
                      dense
                      :disabled="disableEdit"
                      v-model="qualityDiv"
                      :items="locationMngList"
                      :label="$t('label.lbl_locationMngSection')"
                      persistent-hint
                    >
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-row>
                <v-col cols="4" />
                <!-- ２行目 -->
                <v-row class="search-row">
                  <!-- 坪貸区分 -->
                  <div class="search-autocomplete first-search-item">
                    <v-autocomplete
                      dense
                      v-model="rentClassificationDiv"
                      :items="pyeongCoinList"
                      :label="$t('label.lbl_pyeongCoinSection')"
                      persistent-hint
                    >
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-row>
                <v-col cols="4" />
                <!-- ３行目 -->
                <v-row class="search-row">
                  <!-- たて -->
                  <div class="serch-textbox-locDetail first-search-item">
                    <v-text-field
                      outlined
                      dense
                      v-model="inputVertical"
                      :rules="[rules.inputRequired, rules.inputNumber, rules.inputNumberOverZero]"
                      @blur="blurInputVertical"
                      @click="clickPop(inputVertical)"
                      :label="$t('label.lbl_vertical')"
                      clear-icon="mdi-close-circle"
                      class="input-number"
                      persistent-hint
                      clearable
                    >
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- ハイフン -->
                  <div class="hyphen">×</div>

                  <!-- よこ -->
                  <div class="serch-textbox-locDetail">
                    <v-text-field
                      outlined
                      dense
                      v-model="inputWidth"
                      :rules="[rules.inputRequired, rules.inputNumber, rules.inputNumberOverZero]"
                      @blur="blurInputWidth"
                      @click="clickPop(inputWidth)"
                      :label="$t('label.lbl_width')"
                      clear-icon="mdi-close-circle"
                      clearable
                      class="input-number"
                    />
                  </div>
                  <span class="require">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- ハイフン -->
                  <div class="hyphen">×</div>
                  <!-- たかさ -->
                  <div class="serch-textbox-locDetail">
                    <v-text-field
                      outlined
                      dense
                      v-model="inputHeight"
                      @blur="blurInputHeight"
                      @click="clickPop(inputHeight)"
                      :rules="[rules.inputRequired, rules.inputNumber, rules.inputNumberOverZero]"
                      :label="$t('label.lbl_height')"
                      clear-icon="mdi-close-circle"
                      clearable
                      class="input-number"
                    />
                  </div>
                  <span class="require">*</span>
                </v-row>
                <v-col cols="4" />
                <v-col cols="4" />
                <v-row>
                  <!-- 基本単位-->
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <div class="search-autocomplete">
                    <v-text-field
                      dense
                      readonly
                      v-model="baseUnit"
                      :label="$t('label.lbl_basicUnit')"
                      class="txt-single"
                      persistent-hint
                    ></v-text-field>
                  </div>
                </v-row>
                <v-col cols="4" />
                <!-- 4行目 -->
                <v-row class="search-row">
                  <!-- 容量-->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      readonly
                      v-model="totalCapa"
                      :label="$t('label.lbl_capacity')"
                      class="txt-single"
                      persistent-hint
                      :rules="[rules.inputTotalCapa]"
                    ></v-text-field>
                  </div>
                </v-row>
                <v-col cols="4" />

                <!-- 5行目スタート -->
                <v-row class="search-row">
                  <!-- 容量単位-->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      readonly
                      v-model="locationCapacityQuantityUnit"
                      :label="$t('label.lbl_capaUnit')"
                      class="txt-single"
                      persistent-hint
                    ></v-text-field>
                  </div>
                </v-row>
                <v-row style="margin-bottom: 5px">
                  <!-- 検索 -->
                  <div class="search-row-exeBtn">
                    <div class="btn-area">
                      <!-- 閉じるボタン -->
                      <v-btn
                        color="primary"
                        id="btn-search"
                        class="other-btn"
                        @click="closeDetailsInfo()"
                      >
                        {{ $t("btn.btn_close") }}</v-btn
                      >
                      <!-- 登録ボタン -->
                      <v-btn
                        color="primary"
                        id="btn-search"
                        class="other-btn"
                        :disabled="disableBtn"
                        @click="addDetailsInfo()"
                      >
                        {{ $t("btn.btn_insert") }}</v-btn
                      >
                    </div>
                  </div>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- 詳細画面のエラーメッセージ -->
      <v-dialog v-model="validdialog" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ validtMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okvalid"> {{ $t("btn.btn_ok_en") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ロケーション登録500件以上選択時エラーメッセージ -->
      <v-dialog v-model="locationmaxdialog" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ locationmaxdialogMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="locationokClicked"> {{ $t("btn.btn_ok_en") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="postApi"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :okAction="postApi"
        :firstPageFlag="infoDialog.firstPageFlag"
        :locaMstFlg="infoDialog.locaMstFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import ConfirmDialog from "../../components/ConfirmDialog";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_MST_003,
  components: {
    ConfirmDialog,
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ページング
    page: 1,
    itemsPerPage: 100,
    // 詳細画面のエラー
    validdialog: "",
    validtMessage: "",
    disableBtn: false,
    // ロケーション500件以上登録時エラー
    locationmaxdialog: "",
    locationmaxdialogMessage: "",
    // 倉庫マスタ登録より受け取る値
    warehouseCdName: "",
    // バリデーションチェック用
    editedList: [],
    // ロケ一覧から受け取る値
    location: "",
    locationNo: "",
    situation: "",
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 前画面より受け取る値
    backpage: "",
    officeSid: "",
    warehouseSid: "",
    // 倉庫プルダウン
    warehouseList: [],
    // ロケプルダウン
    locationsList: [],
    // 担当者プルダウン
    managerList: [],
    // ロケ優先度プルダウン
    locationGroupPriorityList: [],
    // ロケ管理区分プルダウン
    qualityDiv: "",
    locationMngList: [],
    // 坪貸区分プルダウンy
    rentClassificationDiv: "",
    pyeongCoinList: [],
    // たて
    inputVertical: "",
    // よこ
    inputWidth: "",
    // たかさ
    inputHeight: "",
    // 基本単位
    baseUnit: appConfig.CONSTANT.BASEUNIT,
    totalCapa: "",
    // 容量単位
    locationCapacityQuantityUnit: appConfig.CONSTANT.LOCATIONCAPACITY,
    // 詳細APIから取得する値
    updateDatetime: "",
    // インデックス番号
    index: 0,
    // メニュー
    openMenu: null,
    // ロケーションGr登録ダイアログ
    dialogNewLocationGr: false,
    popNewLocation: "",
    // ロケを選択したインデックス
    newLocationSelectedIndex: 0,
    // 詳細画面ポップアップ
    openDatailsAdd: "",
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // チェックボタンチェック後ダイアログ
    checkDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    locationGroupName: "",
    locationGroup: "",
    // 編集制御フラグ
    disableEdit: false,
    // 表示切替(1:登録画面 2:修正・削除画面)
    displayFlg: "",
    inputList: [],
    // 更新リスト
    updateList: [],
    headerItems: [
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        value: "check",
        align: "center",
        width: "4%",
      },
      // ロケーションNo
      {
        text: i18n.tc("label.lbl_locationNo"),
        value: "locationNo",
        align: "left",
        width: "13%",
        class: "asta",
      },
      // ロケグループ名称
      {
        text: i18n.tc("label.lbl_locationGroupName"),
        value: "locationGroupHeader",
        width: "13%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 担当者
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "popDestinationNm",
        width: "13%",
        align: "left",
        sortable: false,
      },
      // 有効開始日時
      {
        text: i18n.tc("label.lbl_validStartDateAndTime"),
        value: "validFrom",
        width: "13%",
        align: "left",
        class: "asta",
      },
      // 有効終了日時
      {
        text: i18n.tc("label.lbl_validEndDateAndTime"),
        value: "validTo",
        width: "13%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // ロケ優先度
      {
        text: i18n.tc("label.lbl_locationGroupPriority"),
        value: "locationPriorityHeader",
        width: "13%",
        align: "center",
        class: "asta",
      },
      // ロケ一括入力
      {
        text: i18n.tc("label.lbl_locationDetailIn"),
        value: "locationBatchIn",
        width: "13%",
        align: "center",
        class: "asta",
      },
    ],
    rules: {
      inputTotalCapa: (value) =>
        String(value).replaceAll(",", "") <= 9999999.999999 || i18n.tc("check.chk_maxTotalCapa"),
    },
  }),
  methods: {
    // 初期化
    init() {
      this.loadingCounter = 1;
      this.backpage = this.$route.params.backpage;
      this.officeSid = this.$route.params.officeSid;
      this.warehouseCdName = this.$route.params.warehouseCdName;
      this.warehouseSid = this.$route.params.warehouseSid;
      this.situation = this.$route.params.situation;
      this.locationGroup = this.$route.params.locationGroup;

      this.getCodeMst();
      this.getManager();
      if (this.locationGroup == "product0") {
        this.displayFlg = "1";
        this.lineAdd();
      } else if (this.backpage == "MstWarehouseList") {
        this.displayFlg = "1";
        this.searchlocation();
      } else if (this.backpage == "MstLocationListFromDetail") {
        this.displayFlg = "2";
        this.locationNo = this.$route.params.location; //ロケNo
        this.situation = this.$route.params.situation; //状況
        this.searchlocation();
      }
      this.getWarehouse();
      this.getLocation();

      // （倉庫一覧、ロケ一覧）検索項目一時保存
      this.previousSearchFilter = this.$route.params.searchFilter;
    },
    change5(val) {
      let five = commonUtil.zen2han(val);
      five = five.replace(/[^0-9]/gi, "");
      // 前のゼロを削除
      while (five.substring(0, 1) == "0") {
        five = five.replace("0", "");
      }
      five = five.substring(0, 5);
      this.popNewLocation = five;
    },
    /**
     * 商品コードリストボックス取得処理
     */
    checkLocation(value, index) {
      return this.inputList[this.getGlobalIndex(index)].locationHint;
    },
    // 行追加ボタン
    lineAdd() {
      this.inputList.push({
        check: "",
        // officeSid: this.officeSid,
        officeSid: sessionStorage.getItem("sales_office_sid"),
        warehouseSid: this.warehouseSid,
        locationNo: "",
        locationGroupName: "",
        userSid: "",
        locationPriority: "",
        itemCapaUnit: "ｍ",
        qualityDiv: "",
        pyeongCoinList: "",
        inputVertical: null,
        inputWidth: null,
        inputHeight: null,
        baseUnit: appConfig.CONSTANT.BASEUNIT,
        totalCapa: 0,
        validFrom: dateTimeHelper.convertJST().substr(0, 10),
        validTo: "2099/12/31",
        validFromCal: dateTimeHelper.dateCalc(dateTimeHelper.convertJST()),
        validToCal: dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST("2099/12/31".substr(0, 10))
        ),
        locationCapacityQuantityUnit: appConfig.CONSTANT.LOCATIONCAPACITY,
        disableEdit: false,
      });
    },
    // 詳細画面のエラーダイアログを閉じる
    okvalid() {
      this.validdialog = false;
    },
    // ロケーション最大登録可能ダイアログ
    locationokClicked() {
      this.locationmaxdialog = false;
    },
    // 登録ボタン押下時確認ダイアログ
    upadateAdd() {
      // 選択したデータリスト
      var checkList = this.inputList.filter((item) => item.check);
      // 1件もチェックがない場合
      if (checkList.length == 0) {
        this.checkDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }

      // ロケーションチェック
      if (this.$refs.editedList.validate()) {
        // 詳細チェック
        var detailCheckCnt = checkList.filter(
          (item) => !item.inputVertical || !item.inputWidth || !item.inputHeight
        ).length;
        // 詳細にエラーが存在する場合、メッセージを出す
        if (0 < detailCheckCnt) {
          this.validdialog = true;
          this.validtMessage = i18n.tc("check.chk_inputRequiredForm");
          return;
        }

        this.updateList = checkList;
        // 上記でのチェックリストが0件の場合は警告メッセージ表示
        this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      }
    },

    // ロケ詳細画面オープン
    openDetailsAdd(index) {
      if (this.$refs.detailForm) {
        this.$refs.detailForm.resetValidation();
      }
      this.index = this.getGlobalIndex(index);
      // 編集できないデータの場合登録ボタンの非活性化
      if (this.inputList[this.index].disableEdit) {
        this.disableBtn = true;
      } else {
        this.disableBtn = false;
      }

      this.qualityDiv = this.inputList[this.index].qualityDiv; // 品質区分
      this.rentClassificationDiv = this.inputList[this.index].rentClassificationDiv; // 坪貸区分
      this.inputVertical = this.inputList[this.index].inputVertical; // たて
      this.inputWidth = this.inputList[this.index].inputWidth; // よこ
      this.inputHeight = this.inputList[this.index].inputHeight; // たかさ
      this.baseUnit = this.inputList[this.index].baseUnit; // 単位
      this.totalCapa = this.inputList[this.index].totalCapa; // 容量
      this.locationCapacityQuantityUnit = this.inputList[this.index].locationCapacityQuantityUnit; // ロケ容量
      this.openDatailsAdd = true;
      if (this.inputVertical == 0) this.inputVertical = "";
      if (this.inputWidth == 0) this.inputWidth = "";
      if (this.inputHeight == 0) this.inputHeight = "";
    },
    // ロケ詳細画面登録ボタン押下時
    addDetailsInfo() {
      if (this.$refs.detailForm.validate()) {
        this.inputList[this.index].qualityDiv = this.qualityDiv;
        this.inputList[this.index].rentClassificationDiv = this.rentClassificationDiv;
        this.inputList[this.index].totalCapa = this.totalCapa;
        this.inputList[this.index].inputVertical = this.inputVertical;
        this.inputList[this.index].inputWidth = this.inputWidth;
        this.inputList[this.index].inputHeight = this.inputHeight;
        this.inputList[this.index].baseUnit = this.baseUnit;
        this.inputList[this.index].locationCapacityQuantityUnit = this.locationCapacityQuantityUnit;
        this.openDatailsAdd = false;
      }
    },
    // ロケ詳細クローズ
    closeDetailsInfo() {
      this.openDatailsAdd = false;
    },
    // ダイアログを閉じる
    close() {
      this.checkDialogMessage = false;
    },

    postApi() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      //★必須パラメータ
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_COM_009;

      var locationList = [];
      console.log("appConfig.CONSTANT.LOCATIONMAXADD: " + appConfig.CONSTANT.LOCATIONMAXADD);
      if (this.updateList.length < appConfig.CONSTANT.LOCATIONMAXADD) {
        for (let n = 0; n < this.updateList.length; n++) {
          for (let i = 0; i < this.updateList.length; i++) {
            locationList.push({
              locationSid: this.updateList[i].locationGroup ? this.updateList[i].locationGroup : "", //ロケーションSID
              officeSid: this.updateList[i].officeSid ? this.updateList[i].officeSid : "", //営業所SID
              warehouseSid: this.updateList[i].warehouseSid ? this.updateList[i].warehouseSid : "", //倉庫SID
              locationNo: this.updateList[i].locationNo ? this.updateList[i].locationNo : "", //ロケーションNo
              validFrom: dateTimeHelper.convertUTC(this.updateList[i].validFrom), //有効開始日時
              validTo: dateTimeHelper.convertUTC(this.updateList[i].validTo), //有効終了日時
              locationGroup: this.updateList[i].locationGroupName
                ? this.updateList[i].locationGroupName
                : "", //ロケーショングループ
              qualityDiv: this.updateList[i].qualityDiv ? this.updateList[i].qualityDiv : "", //ロケーション管理区分
              itemVertical: this.updateList[i].inputVertical
                ? this.updateList[i].inputVertical
                : "", //たて
              itemBeside: this.updateList[i].inputWidth ? this.updateList[i].inputWidth : "", //よこ
              itemHeight: this.updateList[i].inputHeight ? this.updateList[i].inputHeight : "", //たかさ
              baseUnit: "02", //基本単位
              locationCapacityQuantity: String(this.updateList[i].totalCapa).replaceAll(",", ""), //ロケーション容量
              locationCapacityQuantityUnit: "04", //ロケーション容量単位
              rentClassificationDiv: this.updateList[i].rentClassificationDiv
                ? this.updateList[i].rentClassificationDiv
                : "", //坪貸区分
              userSid: this.updateList[i].userSid ? this.updateList[i].userSid : "", //ユーザーSID
              locationPriority: this.updateList[i].locationPriority
                ? this.updateList[i].locationPriority
                : "", //ロケ優先度
              updateDatetime: this.updateList[i].updateDatetime
                ? this.updateList[i].updateDatetime
                : "", //更新日
            });
          }

          body.reqIdv.locationList = locationList;
          // console.debug("postApi() Body", body);

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.MST_LOCATION, body, appConfig.APP_CONFIG)
              .then((response) => {
                // console.debug("postApi() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = false;
                  this.infoDialog.locaMstFlg = true;
                  this.infoDialog.outsideClickNotCloseFlg = true;
                  resolve(response);
                  // エラー時
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  // this.infoDialog.screenFlag = true;
                  // this.infoDialog.firstPageFlag = true;

                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                // this.infoDialog.screenFlag = true;
                // this.infoDialog.firstPageFlag = true;

                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      } else {
        // ローディング画面表示OFF
        this.loadingCounter = 0;
        this.locationmaxdialog = true;
        this.locationmaxdialogMessage = messsageUtil.getMessage("P-MST-009_004_E");
      }
    },
    // ロケーション一覧取得
    searchlocation() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.reqComComponentId = appConfig.SCREEN_ID.P_MST_003;
      config.params.officeSid = this.officeSid;
      config.params.searchCategory = "1";

      if (this.backpage == "MstLocationListFromDetail") {
        config.params.warehouseSid = this.warehouseSid;
        config.params.locationNo = this.locationNo;
      } else {
        config.params.warehouseSid = this.warehouseSid;
        config.params.locationGroup = this.locationGroup;
      }

      // console.debug("searchlocation() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATION, config)
          .then((response) => {
            // console.debug("searchlocation() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              // ロケーションマスタリストを取得
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  officeSid: row.officeSid, //営業所SID
                  warehouseSid: row.warehouseSid, //倉庫SID
                  locationNo: row.locationNo, //ロケNo
                  locationGroupName: row.locationGroup, //ロケGr名
                  locationGroup: row.locationSid, //ロケSID
                  validFrom: dateTimeHelper.convertUTC2JST(row.validFrom).substr(0, 10), //有効開始
                  validFromCal: dateTimeHelper.dateCalc(
                    dateTimeHelper.convertUTC2JST(row.validFrom).substr(0, 10)
                  ), //有効開始
                  validTo: dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10), //有効終了
                  validToCal: dateTimeHelper.dateCalc(
                    dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10)
                  ), //有効開始
                  inputVertical: row.itemVertical, //たて
                  inputWidth: row.itemBeside, //よこ
                  inputHeight: row.itemHeight, //たかさ
                  baseUnit: this.baseUnit, //基本単位
                  locationCapacityQuantityUnit: this.locationCapacityQuantityUnit, //たかさ
                  totalCapa: row.locationCapacityQuantity,
                  qualityDiv: row.qualityDiv, //ロケ管理区分
                  userSid: row.userSid, //担当者
                  rentClassificationDiv: row.rentClassificationDiv, //坪貸区分
                  locationPriority: String(row.locationPriority), //ロケ優先度
                  updateDatetime: row.updateDatetime, //更新日
                  disableEdit: false,
                });
              });
              for (var a = 0; a < list.length; a++) {
                if (list[a].locationGroupPriority == 1) {
                  list[a].locationGroupPriority == "01";
                } else if (list[a].locationGroupPriority == 2) {
                  list[a].locationGroupPriority == "01";
                }
              }
              this.inputList = list;

              this.checkValidDate();
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 倉庫マスタ取得
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: `${row.warehouseLanguage[0].warehouseName}` + `(${row.warehouseCd})`,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              for (var i = 0; i < list.length; i++) {
                if (list[i].value == null || list[i].value == "") {
                  list.splice(list[i], 1);
                }
                this.warehouseList = list;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // ロケグループプルダウン
    getLocation() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ロケ
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.searchCategoryDiv = "0";
      // 処理区分
      config.params.processDiv = "02";
      config.params.reqComComponentId = appConfig.SCREEN_ID.P_MST_003; // 画面ID
      config.params.officeSid = this.officeSid; // 営業所SID
      config.params.warehouseSid = this.warehouseSid; // 倉庫SID

      // console.debug("getLocation() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
          .then((response) => {
            // console.debug("getLocation() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [
                {
                  text: i18n.tc("label.lbl_locationGroupAdd"),
                  value: "product0",
                  name: i18n.tc("label.lbl_locationGroupAdd"),
                  isNew: false,
                },
              ];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  text: `${i18n.tc("label.lbl_locaGroup") + row.locationGroup}`,
                  value: row.locationGroup,
                  isNew: false,
                });
              });
              this.locationsList = list;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 担当者プルダウン
    getManager() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 担当者
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.searchCategoryDiv = "0";

      // console.debug("getManager() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getManager() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                });
              });

              // 画面の初期値を設定します。
              this.managerList = list;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 過去のものは編集出来ないよう制御
    checkValidDate() {
      var today = new Date();
      for (var t = 0; t < this.inputList.length; t++) {
        if (new Date(this.inputList[t].validTo).getTime() < today.getTime()) {
          this.inputList[t].disableEdit = true;
        } else {
          this.inputList[t].disableEdit = false;
        }
      }
    },
    // ロケ登録プルダウンに追加処理
    changeLocation(value, item, index) {
      // ロケーション登録押下時
      if (value == "product0") {
        // ロケ登録ダイアログを開く
        this.dialogNewLocationGr = true;
        // ロケーション録押下した行のインデックスを保持
        this.newLocationSelectedIndex = this.getGlobalIndex(index);
      }
    },
    // コードマスタ
    getCodeMst() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 坪貸区分
      const rentClassGet = getParameter.getCodeMst(appConfig.CODETYPE.RENTCLASSIFICATION_DIV);
      // ロケ管理区分
      const locMngGet = getParameter.getCodeMst(appConfig.CODETYPE.LOCATION_MNG_DIV);
      // ロケ優先度
      const methodList = getParameter.getCodeMst(appConfig.CODETYPE.LOCATION_PRIO_DIV);

      Promise.all([methodList, rentClassGet, locMngGet])
        .then((result) => {
          // console.debug("getCodeMst() Result", result);
          this.locationGroupPriorityList = result[0];
          this.pyeongCoinList = result[1];
          this.locationMngList = result[2];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // ロケーションGr登録ダイアログを閉じる
    popNewLoationClose() {
      // 値をクリアする
      this.inputList[this.newLocationSelectedIndex].locationGroupName = "";
      this.popNewLocation = "";
      this.newLocationSelectedIndex = "";

      // バリデーションリセット
      this.$refs.locationadd.resetValidation();
      this.dialogNewLocationGr = false;
    },
    // ロケーションGr(明細部分)ダイアログ登録ボタン押下
    popNewLoationSave() {
      if (this.popNewLocation == "") {
        return i18n.tc("check.chk_input");
      }
      // ロケグループの存在チェック
      const locationGr = this.locationsList.find((item) => item.value == this.popNewLocation);
      if (locationGr === undefined) {
        // 登録内容を、ロケグループプルダウンに追加
        this.locationsList.push({
          text: `${i18n.tc("label.lbl_locaGroup") + this.popNewLocation}`,
          value: this.popNewLocation,
          name: this.popNewLocation,
          isNew: true,
        });
        // 追加したロケグループを選択した状態にする
        this.inputList[this.newLocationSelectedIndex].locationGroupName = this.popNewLocation;
        this.inputList[this.newLocationSelectedIndex].locationSelected = this.popNewLocation;
        this.inputList[this.newLocationSelectedIndex].isNew = true;
      } else {
        // 既存のロケグループから選択した状態にする
        this.inputList[this.newLocationSelectedIndex].locationGroupName = locationGr.value;
        this.inputList[this.newLocationSelectedIndex].isNew = true;
      }
      // 値をクリアする
      this.popNewLocation = "";
      this.newLocationSelectedIndex = "";
      // バリデーションリセット
      this.$refs.locationadd.resetValidation();
      this.dialogNewLocationGr = false;
    },
    // 戻るボタン押下
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },
    // 戻るボタンダイアログ：OK押下
    updateOks() {
      this.updateDialogMessage = false;
      if (this.backpage == "MstWarehouseList") {
        this.$router.push({
          name: appConfig.MENU_ID.P_MST_WAREHOUSE_LIST,
          params: {
            searchFilter: this.previousSearchFilter,
            backpage: "MstLocationAdd",
          },
        });
      } else if (
        this.backpage == "MstLocationListFromAdd" ||
        this.backpage == "MstLocationListFromDetail"
      ) {
        this.$router.push({
          name: appConfig.MENU_ID.P_MST_LOCATION_LIST,
          params: {
            searchFilter: this.previousSearchFilter,
            backpage: "MstLocationAdd",
          },
        });
      }
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
    blurInputVertical(val) {
      let number = val.srcElement._value;
      if (number) {
        if (!number.includes(".")) number = number + ".0";
        let num = number.split(".");
        num[0] = num[0].replace(/[^0-9]/gi, "");
        num[1] = num[1].replace(/[^0-9]/gi, "");
        if (num[0].length > 3) {
          num[0] = num[0].substring(0, 3);
        }
        if (num[1].length > 2) {
          num[1] = num[1].substring(0, 2);
        }
        number = num[0] + "." + num[1];
        if (number == 0) {
          number = 0;
        }
        this.inputVertical = commonUtil.formatToCurrency(Number(number));
      }
      this.changeInputTotalCapa();
    },
    blurInputWidth(val) {
      let number = val.srcElement._value;
      if (number) {
        if (!number.includes(".")) number = number + ".0";
        let num = number.split(".");
        num[0] = num[0].replace(/[^0-9]/gi, "");
        num[1] = num[1].replace(/[^0-9]/gi, "");
        if (num[0].length > 3) {
          num[0] = num[0].substring(0, 3);
        }
        if (num[1].length > 2) {
          num[1] = num[1].substring(0, 2);
        }
        number = num[0] + "." + num[1];
        if (number == 0) {
          number = 0;
        }
        this.inputWidth = commonUtil.formatToCurrency(Number(number));
      }
      this.changeInputTotalCapa();
    },
    blurInputHeight(val) {
      let number = val.srcElement._value;
      if (number) {
        if (!number.includes(".")) number = number + ".0";
        let num = number.split(".");
        num[0] = num[0].replace(/[^0-9]/gi, "");
        num[1] = num[1].replace(/[^0-9]/gi, "");
        if (num[0].length > 3) {
          num[0] = num[0].substring(0, 3);
        }
        if (num[1].length > 2) {
          num[1] = num[1].substring(0, 2);
        }
        number = num[0] + "." + num[1];
        if (number == 0) {
          number = 0;
        }
        this.inputHeight = commonUtil.formatToCurrency(Number(number));
      }
      this.changeInputTotalCapa();
    },
    // 容量カンマ処理
    changeInputTotalCapa() {
      let inTotalCapa = this.inputVertical * this.inputWidth * this.inputHeight;
      var newchangefloorspace = inTotalCapa.toFixed(6);
      inTotalCapa = newchangefloorspace;
      inTotalCapa = Number(inTotalCapa);
      let puu = inTotalCapa.toLocaleString("ja-JP", { maximumSignificantDigits: 20 });
      this.totalCapa = puu;
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    // 日付入力
    changeDateFrom(val, index) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }

      if (dateTimeHelper.validDate(strDt, "/")) {
        var globalIndex = this.getGlobalIndex(index);
        // 選択した行の有効開始日も更新
        this.inputList[globalIndex].validFrom = strDt;
        this.inputList[globalIndex].validFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      }
    },
    changeDateTo(val, index) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        var globalIndex = this.getGlobalIndex(index);
        // 選択した行の有効終了日も更新
        this.inputList[globalIndex].validTo = strDt;
        this.inputList[globalIndex].validToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      }
    },
    /**
     * 日付テキストボックスとカレンダーの連動処理
     * カレンダーで選択した日付がテキストボックスに反映される
     */
    inputCallenderFrom(validFromCal, index) {
      const globalIndex = this.getGlobalIndex(index);
      this.inputList[globalIndex].validFrom = this.formatDate(validFromCal);
      this.inputList[globalIndex].dateMenuFrom = false;
    },
    inputCallenderTo(validToCal, index) {
      const globalIndex = this.getGlobalIndex(index);
      this.inputList[globalIndex].validTo = this.formatDate(validToCal);
      this.inputList[globalIndex].dateMenuTo = false;
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 有効期間、ロケーションチェック
     */
    inputValidDateCheck(item, index) {
      // 対象ロケーションNo、ロケーションGr
      const targetList = this.inputList
        .map((data, i) => {
          if (
            data.check &&
            item.locationNo == data.locationNo &&
            item.locationGroupName == data.locationGroupName
          ) {
            return { ...data, index: i };
          }
        })
        .filter((item) => item !== undefined);

      // 対象データが2件以上の場合、処理する
      const targetLength = targetList.length;
      if (targetLength < 2) {
        return true;
      }

      // 対象が何番目なのか
      const globalIndex = this.getGlobalIndex(index);
      const targetIndex = targetList.findIndex((data) => data.index == globalIndex);

      if (targetLength - 1 == targetIndex) {
        // 最後のデータ
        if (
          targetList[targetIndex - 1].validFrom <= targetList[targetIndex].validTo &&
          targetList[targetIndex - 1].validTo >= targetList[targetIndex].validFrom
        ) {
          return i18n.tc("check.chk_period_duplicate");
        }
      } else {
        if (
          targetList[targetIndex].validFrom <= targetList[targetIndex + 1].validTo &&
          targetList[targetIndex].validTo >= targetList[targetIndex + 1].validFrom
        ) {
          return i18n.tc("check.chk_period_duplicate");
        }
      }

      return true;
    },
    // 全体のインデックス取得
    getGlobalIndex(index) {
      return index + this.itemsPerPage * (this.page - 1);
    },
  },
  created() {
    this.init();
    this.baseUnit = appConfig.CONSTANT.BASEUNIT;
    this.locationCapacityQuantityUnit = appConfig.CONSTANT.LOCATIONCAPACITY;
  },
  computed: {},
  watch: {
    // たて全角半角変換処理
    inputVertical(val) {
      this.inputVertical = commonUtil.zen2han(val);
    },
    // たかさ全角半角変換処理
    inputHeight(val) {
      this.inputHeight = commonUtil.zen2han(val);
    },
    // よこ全角半角変換処理
    inputWidth(val) {
      this.inputWidth = commonUtil.zen2han(val);
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    validFromCal(val) {
      this.validFrom = this.formatDate(val);
    },
    validSEndDateCal(val) {
      this.validTo = this.formatDate(val);
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.search-textbox-remark {
  width: 41.5rem;
  margin-left: 15px;
}
.calender {
  display: flex;
}
// ロケグループ入力テキストボックス
.add-textbox {
  width: 10rem;
  padding: 20px 0 0 10px;
}
// 実行ボタンがある行
.search-row-exeBtns {
  width: 100%;
  margin-right: 12px;
}
// 検索項目間の空白
.item-spacers {
  margin-left: 50px;
  margin-right: 6px;
}
.isD {
  background-color: $color-warning !important;
}
.locationAlign {
  margin: 9px 0 0 0;
  height: 3.6rem;
  text-align: left;
}
</style>
